/* service */
.service {
    margin-bottom: 60px;
}

.service__img {
    margin-bottom: 45px;
    width: 100%;
    position: relative;
}

.service__img img,
.service__img picture {
    display: block;
    margin: 0 auto;
    max-width: 100%;
}

.service__name {
    text-transform: uppercase;
    margin: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #fefefe;
    font-size: 24px;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
}

.service__content {
    display: flex;
    justify-content: space-between;
}

.service__info {
    box-sizing: border-box;
    width: 51%;
    padding-left: 50px;
}

.service__info p {
    color: #2f2f2f;
    font-size: 15px;
    line-height: 1.6em;
    margin-bottom: 30px;
}

.service-price__wrap {
    width: 35%;
    margin-right: 50px;
}

.service-price {
    box-sizing: border-box;
    background: #eee9d2;
    padding: 30px 40px;
}

.service-price__item {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    margin-bottom: 10px;
}

.service-price__item_name {
    width: 50%;
}

.service-price__item_price {
    white-space: nowrap;
    overflow: hidden;
    width: 40%;
}

.service-price__item_link {
    font-size: 16px;
    padding-left: 30px;
    margin-top: 20px;
    position: relative;
}

.service-price__item_link a {
    color: #f6a922;
    font-weight: normal;
}

.service-price__item_link a:hover {
    text-decoration: none;
}

.service-price__item_link::before {
    content: '';
    background: url(../img/doc.png);
    background-repeat: no-repeat;
    background-position: left center;
    position: absolute;
    left: 0;
    width: 18px;
    height: 21px;
}

/* service */

/* media service */
@media (max-width: 950px) {
    .service-price__wrap {
        width: 40%;
    }
}

@media (max-width: 900px) {
    .service__name {
        letter-spacing: 3px;
    }
}

@media (max-width: 900px) and (min-width: 601px) {
    .service__info {
        padding-left: 10px;
    }

    .service-price__wrap {
        margin-right: 10px;
        width: 45%;
    }

    .service-price {
        padding: 30px 20px;
    }

    .service-price__item_name {
        width: 60%;
    }
}

@media (max-width: 600px) {
    .service {
        margin-bottom: 0;
    }

    .service__img {
        margin-bottom: 0;
    }

    .service__img img,
    .service__img picture {
        height: 100%;
    }

    .service__content {
        flex-wrap: wrap-reverse;
        color: #2f2f2f;
    }

    .service-price__wrap {
        margin-right: 0;
        width: 100%;
        background: #eee9d2;
    }

    .service-price {
        width: 98%;
        max-width: 280px;
        margin: 0 auto;
        padding: 30px 0;
    }

    .service-price__item_link {
        margin: 25px auto 0;
    }

    .service__name {
        width: 100%;
        text-align: center;
    }

    .service__info {
        width: 90%;
        padding: 30px 0 35px;
        margin: 0 auto;
    }
}

@media (max-width: 481px) {
    .service__img {
        height: 150px;
    }
}

/* media service */
