/* partners */
.partners {
    height: 260px;
    width: 100%;
    background: #f7f5ec;
}

.partners__wrap {
    width: 96%;
    margin: 0 auto;
}

.partners-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

/* partners */

@media (max-width: 750px) {
    .partners__wrap {
        width: 100%;
        overflow: hidden;
    }

    .partners-list {
        width: 140vw;
        transform: translate(-25%, 0);
    }
}

@media (max-width: 600px) {
    .partners-list {
        width: 200vw;
    }
}
