/* footer */
.footer {
    height: 140px;
    font-size: 14px;
    font-weight: bold;
    color: #b0aa8d;
}

.footer-wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer-top {
    display: flex;
}

.footer-menu {
    width: 85%;
}

.footer-menu__list {
    height: 100%;
}

.footer-menu__item a {
    color: #b0aa8d;
}

.footer-menu__item a:hover {
    color: #ea840b;
}

.footer-social {
    width: 25%;
}

.footer-social__item {
    margin: 0 5px;
}

.footer-copy {
    text-align: center;
    font-family: Tahoma;
    font-weight: normal;
    padding-top: 20px;
}

/* footer */

/* media footer */
@media (max-width: 1000px) {
    .footer-top {
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
    }

    .footer-menu {
        width: 95%;
        margin-bottom: 10px;
    }

    .footer-copy {
        padding-top: 10px;
    }
}

@media (max-width: 600px) {
    .footer-menu {
        display: none;
    }
}

@media (max-width: 500px) {
    .footer {
        height: 270px;
    }

    .footer-wrap {
        height: 80%;
    }

    .footer-social {
        flex-wrap: wrap;
        width: 100%;
        justify-content: center !important;
        margin-bottom: 20px;
    }

    .footer-copy {
        padding-top: 20px;
    }
}

/* media footer */
