@import "normalize-css";

html {
    font-family: 'PT Sans', sans-serif;
}

.container {
    margin: 0 auto;
    width: 98%;
    max-width: 1000px;
}

.nice {
    font-size: 30px;
    color: #ccc297;
    text-align: center;
    padding: 20px;
    box-shadow: 0 4px 3px 0 rgba(0, 0, 0, 0.3);
    margin: 0;
    font-weight: normal;
}

.mapdummy {
    height: 460px;
    width: 100%;
    overflow: hidden;
}

.mapdummy img {
    margin: 0 auto;
    width: 1920px;
}

/* media help */
@media (max-width: 1000px) {
    .container {
        width: 100%;
    }
}

@media (max-width: 900px) {
    .hidden-md {
        display: none !important;
    }

    .visible-md {
        display: block !important;
    }
}

@media (max-width: 600px) {
    .hidden-sm {
        display: none !important;
    }

    .visible-sm {
        display: block !important;
    }
}

@media (max-width: 500px) {
    .hidden-xs {
        display: none !important;
    }

    .visible-xs {
        display: block !important;
    }
}

/* media help */

/* help */
.height-100 {
    height: 100%;
}

.hidden {
    display: none !important;
}

.visible-md {
    display: none !important;
}

.visible-sm {
    display: none !important;
}

.visible-xs {
    display: none !important;
}

.anchor {
    display: none;
}

.controls {
    width: 96%;
    height: 80px;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    position: absolute;
}

.next,
.prev {
    opacity: 0.7;
    height: 80px;
    width: 45px;
    background-repeat: none;
    background-position: center center;
    cursor: pointer;
}

.next {
    background-image: url(../img/white_arrow_right.png);
}

.prev {
    background-image: url(../img/white_arrow_left.png);
}

.next:hover,
.prev:hover {
    opacity: 1;
}

/* help */
