/* news */
.news {
    min-height: 285px;
    padding: 80px 0;
    box-sizing: border-box;
    position: relative;
}

.news-carousel {
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.news-item {
    width: 30%;
}

.news-item__header {
    font-size: 20px;
    line-height: 1em;
    padding-bottom: 15px;
    color: #2f2f2f;
}

.news-item__header h3 {
    margin: 0;
}

.news-item__info {
    font-size: 14px;
    font-family: Tahoma;
    color: #707070;
}

.news-item__info p {
    margin: 0;
}

.news-item__more {
    font-size: 14px;
    font-family: Tahoma;
}

.news-item__more a {
    color: #707070;
}

.news-item__more a:hover {
    text-decoration: none;
    color: #ea840b;
}

.news__controls {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* news */

@media (max-width: 1200px) {
    .news-carousel {
        justify-content: space-around;
    }

    .news-item {
        width: 40%;
    }

    .hidden-md_news {
        display: none !important;
    }
}

@media (max-width: 1060px) {
    .news-carousel {
        width: 88%;
    }
}

@media (max-width: 750px) {
    .news {
        padding: 25px 0 40px;
        min-height: auto;
    }

    .news-item {
        width: 70%;
    }

    .news-carousel {
        justify-content: center;
    }

    .hidden-sm_news {
        display: none !important;
    }
}
