/* set9 */
.set-9 {
    background: #f3f3f3 url(../img/pattern_2.png) repeat;
    overflow: hidden;
    position: relative;
}

.set9 {
    position: relative;
}

.set9:hover {
    z-index: 100;
}

.set-9__wrap {
    display: flex;
    justify-content: space-between;
    width: 90%;
    max-width: 900px;
    padding: 25px 0 90px;
}

.set9__face {
    display: block;
    transition: box-shadow 0.2s;
    border-radius: 50%;
    background: #f3f3f3;
    width: 200px;
    height: 200px;
    position: relative;
    cursor: pointer;
    color: #fff;
    margin: 35px 50px;
    z-index: 3;
}

.set9__face::before {
    content: '';
    background-position: center center;
    background-repeat: no-repeat;
    display: block;
    width: 200px;
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
}

.set9__face::after {
    box-shadow: 0 0 0 8px;
    transition: transform 0.2s, opacity 0.2s;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    content: '';
    box-sizing: content-box;
}

.set9__face:hover {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 1);
    color: #fff;
}

.set9:hover .set9__desc {
    display: flex;
}

.set9__face:hover::after {
    transform: scale(0.85);
    opacity: 0.3;
}

.set9__face_petrenko .set9__face::before {
    background: url(../img/petrenko.png);
}

.set9__face_trushina .set9__face::before {
    background: url(../img/trushina.png);
}

.set9__face_dobrunova .set9__face::before {
    background: url(../img/dobrunova.png);
}

.set9__face_trushina .set9__desc {
    font-size: 15px;
    line-height: 1.3em;
}

.set9__name {
    font-size: 18px;
    font-weight: bold;
    color: #6f6f6f;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    z-index: 3;
    position: relative;
}

.set9__job {
    font-size: 18px;
    font-style: italic;
    color: #9e9170;
    text-align: center;
    font-weight: normal;
    margin: 0;
    z-index: 3;
    position: relative;
}

.set-9__controls {
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.set9__desc {
    background: #d9f164;
    font-size: 16px;
    color: #000;
    line-height: 1.4em;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    height: 110%;
    width: 630px;
    opacity: 0.94;
    display: none;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 30px 45px 0;
}

.set9__desc p {
    margin: 0;
}

.set9__desc_left {
    left: initial;
    right: 0;
    justify-content: flex-start;
}

.set9__close {
    width: 24px;
    height: 24px;
    background: url(../img/close.png);
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.set9__desc_left .set9__close,
.set9__desc_left-md .set9__close {
    left: 10px;
    right: initial;
}

.set9__content {
    width: 58%;
}

.diplom {
    position: relative;
    padding-left: 30px;
    margin-top: 10px;
}

.diplom::before {
    content: '';
    width: 25px;
    height: 25px;
    left: 0;
    top: -1px;
    background: url(../img/star.png) no-repeat center center;
    position: absolute;
}

.diplom a {
    border-bottom: 1px dotted #d27130;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    color: #d27130;
    text-decoration: none;
}

.diplom a:hover {
    border-bottom: none;
}

/* set9 */

@media (max-width: 1050px) {
    .set-9__wrap {
        justify-content: space-around;
    }

    .hidden-md_set9 {
        display: none !important;
    }

    .set9__desc_left-md {
        left: initial;
        right: 0;
        justify-content: flex-start;
    }
}

@media (max-width: 750px) {
    .set9 {
        margin: 0 auto;
    }

    .set-9__wrap {
        padding: 0 0 15px;
    }

    .hidden-sm__set9 {
        display: none !important;
    }
}
