/* slider */
.slider {
    position: relative;
}

.slide {
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 590px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.slide-info {
    position: absolute;
    color: #fff;
    top: 35%;
    left: 50%;
    transform: translate(-20%, -50%);
    white-space: nowrap;
}

.slide-info__header {
    font-size: 72px;
    line-height: 72px;
    font-weight: bold;
}

.slide-info__info {
    margin-top: 15px;
    font-size: 36px;
}

.slide-info__more {
    font-size: 20px;
    font-weight: bold;
    border: 2px solid #fff;
    border-radius: 40px;
    padding: 15px 30px;
    cursor: pointer;
    margin-top: 30px;
    display: inline-block;
}

.slide-desc {
    margin-top: -4px;
    background: #f7f5ec;
    height: 275px;
}

.slide-desc__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.slide-desc__icon {
    width: 35%;
}

.slide-desc__text {
    width: 65%;
    color: #372d31;
    font-size: 19px;
    line-height: 30px;
}

.slide-desc__logo {
    font-size: 40px;
    line-height: 40px;
    color: #2f2f2f;
    position: relative;
}

.slide-desc__logo::before {
    content: '';
    background: url(../img/leaf.png) no-repeat center center;
    width: 56px;
    height: 42px;
    top: -48px;
    left: 0;
    position: absolute;
    display: block;
}

.slide-desc__button {
    margin-top: 20px;
    display: inline-block;
    color: #8f9541;
    font-size: 18px;
    font-weight: bold;
    border-radius: 20px;
    padding: 5px 25px;
    cursor: pointer;
    background: linear-gradient(0deg, #b7ce46, #d1e95b);
}

.slide-desc__button:hover {
    background: linear-gradient(0deg, #d1e95b, #b7ce46);
}

.slider__controls {
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.slider__controls .next,
.slider__controls .prev {
    opacity: 0.2;
    transition: opacity 0.3s ease;
    width: 14px;
    height: 43px;
    background: url(../img/promo-block__nav-sprite.png) no-repeat;
}

.slider__controls .next {
    background-position: -14px 0;
}

.slider__controls .next:hover,
.slider__controls .prev:hover {
    opacity: 1;
}

/* slider */

@media (max-width: 1060px) {
    .slide-desc__wrap {
        width: 94%;
        margin: 0 auto;
    }
}

@media (max-width: 900px) {
    .slide img {
        height: 370px;
    }

    .slide-desc {
        height: auto;
    }

    .slide-desc__wrap {
        box-sizing: border-box;
        flex-wrap: wrap;
        width: 100%;
        padding: 100px 60px 80px;
    }

    .slide-desc__icon {
        width: 100%;
    }

    .slide-desc__text {
        lihe-height: 25px;
        width: 100%;
    }

    .slide-desc__icon {
        margin-bottom: 20px;
    }

    .slide-desc__logo::before {
        left: -15px;
    }

    .slide-info {
        left: 0;
        top: 80px;
        transform: none;
        width: 100%;
        padding-left: 20%;
        box-sizing: border-box;
    }

    .slide-info__info {
        font-size: 22px;
    }

    .slide-info__more {
        color: #8f9541;
        background: linear-gradient(0deg, #b6cd45, #d1e95b);
        border: none;
        font-size: 18px;
        font-weight: bold;
        padding: 7px 29px;
    }
}

@media (max-width: 710px) {
    .slide-info__header {
        font-size: 44px;
        line-height: 44px;
    }

    .slide {
        height: 450px;
    }

    .slider__controls {
        top: 25%;
    }
}

@media (max-width: 481px) {
    .slide {
        height: 370px;
        background-position: left;
    }

    .slide-info {
        white-space: normal;
    }

    .slider__controls {
        top: 21%;
    }

    .slide-info {
        top: 50px;
        padding-left: 15%;
    }
}
