/* gallery */
.gallery {
    width: 100%;
    display: flex;
    background: #ffbb37;
    padding: 0 2.5%;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
}

.gallery__item {
    width: 30.5%;
    margin: 30px 0;
    border: 10px solid #db9f29;
}

.gallery__item img {
    width: 100%;
    height: auto;
}

/* gallery */

@media (max-width: 900px) {
    .gallery {
        padding: 0 2%;
    }

    .gallery__item {
        width: 29.5%;
        margin: 20px 0;
    }
}

@media (max-width: 600px) {
    .gallery {
        flex-wrap: wrap;
        overflow: hidden;
        padding: 0;
    }

    .gallery__item {
        width: 100%;
        margin: 15px auto 12px;
        border: none;
    }
}
