/* mobile-menu */
.mobile-menu {
    width: 100%;
    background-color: #d9f164;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    padding: 0 0 90px;
}

.mobile-menu__top {
    display: flex;
    justify-content: space-between;
    padding: 5px 20px 40px 15px;
}

.mobile-menu__logo {
    background: url(../img/mobileLogo.png);
    width: 88px;
    height: 65px;
}

.mobile-menu__close {
    display: block;
    background: url(../img/mobileClose.png);
    width: 28px;
    height: 28px;
    margin-top: 20px;
    opacity: 0.8;
    cursor: pointer;
}

.mobile-menu__close:hover {
    opacity: 1;
}

.mobile-menu__content {
    margin: 20px 0 0;
    padding: 0 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mobile-menu__item {
    margin: 20px 0;
}

.mobile-menu__item a {
    color: #72831f;
}

.mobile-menu__item a:hover {
    color: #ea840b;
}

.social__item_mobile {
    width: 176px;
    margin: 0 auto;
}

/* mobile-menu */

/* mobile-menu height */
@media (max-height: 800px) {
    .mobile-menu {
        padding-bottom: 20px;
    }

    .mobile-menu__top {
        padding-bottom: 20px;
    }

    .mobile-menu__content {
        padding: 0;
    }

    .mobile-menu__item {
        margin: 0;
        height: 7vh;
        min-height: 40px;
    }
}

/* mobile-menu height */
