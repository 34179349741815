/* quote */
.quote {
    padding: 60px 0 15px;
    min-height: 100%;
    overflow: hidden;
}

.quote__content {
    width: 100%;
    padding: 0 8%;
    box-sizing: border-box;
    margin: 0 auto;
    font-size: 20px;
    font-weight: bold;
    font-style: italic;
    color: #b49d63;
    line-height: 1.4em;
    text-align: center;
    position: relative;
}

.quote__content::before,
.quote__content::after {
    content: '';
    width: 47px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}

.quote__content::before {
    background-image: url(../img/quote2.png);
    left: 10px;
}

.quote__content::after {
    background-image: url(../img/quote1.png);
    right: 10px;
}

.quote_datename {
    margin: 10px 0;
    text-align: center;
    font-size: 18px;
    color: #b49d63;
}

.quote-form {
    margin: 40px auto;
    width: 400px;
    position: relative;
}

.quote-form .close {
    position: absolute;
    top: -36px;
    right: -26px;
    cursor: pointer;
    font-family: Georgia;
    font-size: 18px;
}

.quote-form input,
.quote-form textarea {
    font-size: 16px;
    padding: 0 12px;
    border: 1px solid #d9f164;
    color: #b49d63;
    margin-bottom: 13px;
}

.quote-form textarea {
    padding: 5px 10px;
}

.quote_button {
    background: url(../img/quote_button.png) center no-repeat;
    width: 167px;
    height: 30px;
    margin: 0 auto 13px;
    padding: 5px 10px;
    cursor: pointer;
    margin: 0 auto;
    display: block;
    border: none !important;
}

.quote_form_wrapper {
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.quote_button:hover {
    background-image: url(../img/quote_button_hover.png);
}

/* quote */

@media (max-width: 800px) {
    .quote {
        padding-bottom: 20px;
    }

    .quote__content {
        padding: 0 15%;
    }

    .quote__content::before {
        top: 25px;
    }

    .quote__content::after {
        top: 87%;
    }
}
