/* info */
.info {
    display: flex;
}

.info_mobile {
    flex-wrap: wrap;
    height: 75%;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.info_column {
    flex-direction: column;
    align-items: flex-end;
}

.info a {
    text-decoration: none;
    color: #8e8e8e;
}

.info__wrap {
    height: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
}

.info__item {
    position: relative;
    padding-left: 31px;
    height: 25px;
    line-height: 25px;
    margin-top: 5px;
}

.info__item_padded {
    padding-right: 15px;
}

.info__item::before {
    content: '';
    height: 22px;
    width: 21px;
    position: absolute;
    left: 0;
    background-repeat: no-repeat;
    background-position: left center;
}

.info__item_phone::before {
    background-image: url(../img/phone.png);
}

.info__item_time::before {
    background-image: url(../img/time.png);
}

.info__item_address::before {
    background-image: url(../img/pin.png);
}

.info__item_address h4 {
    margin: 0;
}

/* info */
