/* header */
.header {
    height: 153px;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: bold;
    color: #8e8e8e;
}

.header-top {
    height: 95px;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.header-top__wrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-menu {
    height: 58px;
    background: #d9f164;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    font-size: 15px;
}

.header-menu__wrap {
    height: 100%;
}

.header-menu__list {
    height: 58px;
}

.header-menu__item {
    display: block;
}

.header-menu__item a {
    display: block;
    height: 100%;
    width: 100%;
    color: #72831f;
}

.header-menu__item a:hover {
    color: #ea840b;
}

/* header */

/* media header */
@media (max-width: 900px) {
    .header-top {
        height: 85px;
        box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
        border-bottom: 1px solid #dad9d9;
    }

    .header-top__logo {
        padding-left: 15px;
    }

    .header-top__logo img {
        height: 72px;
    }

    .header {
        height: 200px;
    }

    .header-menu {
        height: 115px;
        background: #f7f5ec;
    }
}

/* media header */
