/* loyality */
.loyality {
    background: #d9f164;
    color: #68722b;
    font-size: 16px;
    font-family: Tahoma;
}

.loyality_wrapper {
    width: 65%;
}

.loyality_wrapper h2 {
    font-family: 'PT Sans';
    font-size: 30px;
    font-weight: normal;
    padding-left: 35px;
    position: relative;
    margin: 0;
    padding-bottom: 15px;
}

.loyality_wrapper h2::before {
    content: '';
    background: url(../img/present.png) no-repeat center center;
    position: absolute;
    left: 0;
    width: 28px;
    height: 28px;
}

.loyality_wrapper p {
    line-height: 26px;
    padding-top: 0;
    margin: 0;
}

.loyality_wrapper ul {
    padding: 20px 0 20px 18px;
    margin: 0;
}

.loyality_wrapper li {
    line-height: 26px;
}

.loyality__inner {
    position: relative;
    padding: 40px 0;
}

.loyality_button {
    background: linear-gradient(to top, #bcd34a, #cee558);
    display: inline-block;
    color: #9ea44c;
    font-size: 18px;
    padding: 5px 20px 7px;
    border-radius: 20px;
    text-decoration: none;
    margin-top: 20px;
}

.loyality_button:hover {
    background: linear-gradient(to top, #cee558, #bcd34a);
}

.loyality__inner img {
    position: absolute;
    bottom: 0;
    right: 10px;
}

/* loyality */

@media (max-width: 800px) {
    .loyality__inner {
        padding-bottom: 10px;
    }

    .loyality_wrapper {
        width: 90%;
        margin: 0 auto;
        text-align: center;
        padding: 25px 0 0;
    }

    .loyality_wrapper h2 {
        font-size: 18px;
        font-weight: normal;
        padding-left: 0;
        padding-bottom: 25px;
    }

    .loyality_wrapper h2::before {
        top: -40px;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .loyality_wrapper p {
        font-size: 14px;
        line-height: 20px;
        padding-top: 0;
        padding-bottom: 20px;
    }

    .loyality_wrapper ul {
        text-align: left;
        padding: 0;
        width: 85%;
        margin: 0 auto 30px;
    }

    .loyality_wrapper li {
        margin-bottom: 10px;
        font-size: 15px;
    }

    .loyality__important {
        font-size: 18px !important;
        line-height: 26px !important;
    }
}

@media (max-width: 1060px) {
    .loyality_wrapper {
        margin: 0 auto;
    }

    .hidden-md_loyality {
        display: none !important;
    }
}
