/* menu-list */
.menu-button {
    padding: 13px 0;
    position: relative;
    margin-right: 15px;
}

.menu-button__line,
.menu-button::before,
.menu-button::after {
    width: 34px;
    height: 5px;
    background: #d9f164;
}

.menu-button::before,
.menu-button::after {
    content: '';
    position: absolute;
}

.menu-button::before {
    top: 0;
}

.menu-button::after {
    bottom: 0;
}

.menu-list {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    justify-content: space-around;
}

.menu-list__item {
    display: block;
}

.menu-list__item a {
    text-transform: uppercase;
    text-decoration: none;
}

/* menu-list */
