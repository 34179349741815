/* social */
.social {
    display: flex;
    justify-content: space-around;
}

.social__item {
    display: block;
}

.social__item img {
    opacity: 0.8;
}

.social__item:hover img {
    opacity: 1;
}

.social__item_padded {
    margin-top: 10px;
}

/* social */
